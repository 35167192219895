import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store.js";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import Alert from "./components/Alert.vue";
import Dialog from "./components/Dialog";
import Notification from "./components/Notification";
import JsonViewer from "vue-json-viewer";

Vue.component("Dialog", Dialog);
Vue.component("Notification", Notification);
Vue.use(JsonViewer);
Vue.use(Vuelidate);
Vue.config.productionTip = false;

store.subscribe((mutation, state) => {
   sessionStorage.setItem("userdata", JSON.stringify(state.userdata));
});

new Vue({
   router,
   store,
   vuetify,
   beforeCreate() {
      store.commit("initializeStore");
   },
   render: (h) => h(App),
}).$mount("#app");

const noti = new Vue({
   render: (h) => h(Alert),
}).$mount("#noti");

Vue.prototype.$noti = (msj) => {
   noti.$children[0].Show(msj);
};

router.beforeEach(async function (to, from, next) {
   try {
      let authorized = false;
      if (to.meta.require) {
         if(store.state.userdata.token){
            authorized = true;
         } else {
            authorized = false;
         }         
      } else {
         authorized = true;
      }

      if (authorized == true) {
         next();
      } else {
         router.push({
            name: "Login",
            query: {
               status: "denied",
               time: new Date().getTime(),
            },
         });
      }
   } catch (error) {
      next(error);
   }
});