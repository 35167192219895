<template>
   <v-expand-transition>
      <v-alert v-model="show" dismissible v-show="show" :type="type">
         {{ message + (append.length > 0 ? ": " : " ") + append }}
         <json-viewer v-if="is_json" contextmenu :value="json"></json-viewer>
      </v-alert>
   </v-expand-transition>
</template>

<script>
export default {
   props: {
      message: String,
      type: {
         type: String,
         default: "error",
      },
      duration: {
         type: Number,
         default: 5000,
      },
   },
   data: function () {
      return {
         show: false,
         is_json: false,
         json: {},
         timeout: null,
         local_duration: 5000,
         append: "",
      };
   },
   methods: {
      async Show(msj) {
         this.local_duration = this.duration
         this.show = true;
         this.is_json = false;
         this.json = {};
         if (msj) {
            if (typeof msj === "string" || msj instanceof String) {
               this.append = msj;
            } else if (typeof msj === "error" || msj instanceof Error) {
               this.append = msj.message;
            } else {
               this.json = msj;
               this.is_json = true;
               if (msj.data) {
                  this.local_duration = 10000;
                  this.json = msj.data;
                  if (msj.data.message) {
                     this.append = msj.data.message;
                  }
                  if (msj.data.data) {
                     this.json = msj.data.data;
                  }
               }
            }
            this.append = this.append.substring(0, 100);
         }
         this.timeout = await setTimeout(() => {
            this.show = false;
         }, this.local_duration);
         return true;
      },
      close() {
         clearTimeout(this.timeout);
      },
   },
   watch: {
      show: function () {
         if (this.show == false) {
            this.close();
         }
      },
   },
};
</script>

<style scoped>
.v-alert {
   position: fixed;
   left: 50%;
   bottom: 50px;
   transform: translate(-50%, 0);
   max-width: 400px;
   width: max-content;
   z-index: 999;
}
</style>
