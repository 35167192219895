import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
   {
      path: "/",
      name: "Login",
      component: () => import("./views/Login.vue"),
      meta: {
         require: false,
         title: "Login",
         icon: "mdi-login",
         show: false,
      },
   },
   {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("./views/Dashboard.vue"),
      meta: {
         require: true,
         title: "Dashboard",
         icon: "mdi-home",
         show: false,
      },
   },
   {
      path: "/state",
      name: "State",
      component: () => import("./views/State.vue"),
      meta: {
         require: true,
         title: "State",
         icon: "mdi-code-json",
         show: false,
      },
   },
   {
      path: "/products",
      name: "Products",
      component: () => import("./views/Products.vue"),
      meta: {
         require: true,
         title: "Productos",
         icon: "mdi-archive-lock",
         show: true,
      },
   },
   {
      path: "/info",
      name: "Info",
      component: () => import("./views/Info.vue"),
      meta: {
         require: true,
         title: "Info",
         icon: "mdi-information-outline",
         show: true,
      },
   },
   {
      path: "/users",
      name: "Users",
      component: () => import("./views/Users.vue"),
      meta: {
         require: true,
         title: "Usuarios",
         icon: "mdi-account-group",
         show: true,
      },
   },
   {
      path: "/API_KEYS",
      name: "API_KEYS",
      component: () => import("./views/API_Keys.vue"),
      meta: {
         require: true,
         title: "API_Keys",
         icon: "mdi-key",
         show: true,
      },
   },
];

const router = new VueRouter({
   routes,
});

export default router;

