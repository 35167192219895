<template>
   <v-app>
      <v-main>
         <Header />
         <router-view />
         <!-- <Footer /> -->
      </v-main>
   </v-app>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
   components: {
      Header,
      Footer,
    }, //mounted() {
   // },
};
</script>

<style>
.inputnum>>>input[type="number"] {
   appearance: textfield;
}

.inputnum>>>input::-webkit-outer-spin-button,
.inputnum>>>input::-webkit-inner-spin-button {
   appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
}

html {
   overflow-y: auto;
}

::-webkit-scrollbar {
   width: 8px;
   height: 8px;
}

::-webkit-scrollbar-track {
   background: #757575;
}

::-webkit-scrollbar-thumb {
   background: #e7e7e7;
   border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
   background: #444444;
}
.custom-button .custom-text {
   font-weight: bold;
  color: #1e1e1e; /* Ajusta este valor al color primary que necesitas */
}

</style>
