import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

let darkt = false;
if (localStorage.getItem("dark")) {
  darkt = JSON.parse(localStorage.getItem("dark"));
}

export default new Vuetify({
  theme: {
    dark: darkt,
    themes: {
      light: {
        primary: "#E0E0E0",
        secondary: "#1e1e1e",
        bg: "#E0E0E0",
        hovermenu: "#BDBDBD",
        botones: "#455A64",
      },
      dark: {
        primary: "#1e1e1e",
        secondary: "#f1f1f1",
        bg: "#1e1e1e",
        hovermenu: "#2d2d2d",
        botones: "#ECEFF1",
      },
    },
    options: { customProperties: true },
  },
});
