import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import c from "./constant";

Vue.use(Vuex);

axios.defaults.baseURL = c.API_URL;
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common["Pragma"] = "no-cache";
axios.defaults.headers.common["Expires"] = "0";

const formAxios = axios.create({
   transformRequest: [
      function (data, headers) {
         if (headers["Content-Type"] && headers["Content-Type"].startsWith("multipart/form-data")) {
            const form = new FormData();
            for (const key in data) {
               const value = data[key];
               if (Array.isArray(value)) {
                  const arrayKey = `${key}[]`;
                  value.forEach((v) => {
                     form.append(arrayKey, v);
                  });
               } else {
                  form.append(key, value);
               }
            }
            return form;
         }
         return data;
      },
   ],
});

const getCacheableState = () => {
   return {
      username: "",
      token: "",
   };
};
const getDefaultState = () => {
   return {
      userdata: getCacheableState(),
      public_key: null,
      users: [],
      products: [],
      licencias: {
         items: [],
         totalItems: 10,
         options: {},
      },
      selectedHardware:[],
      hardwares:[]
   };
};

export default new Vuex.Store({
   state: getDefaultState(),
   mutations: {
      initializeStore(state) {
         let userdata = null;
         if (sessionStorage.getItem("userdata")) {
            userdata = JSON.parse(sessionStorage.getItem("userdata"));
         }
         if (userdata.token) {
            if (userdata.token.length > 0) {
               state.userdata = userdata;
               axios.defaults.headers.common["API-KEY"] = state.userdata.token;
            }
         }
      },
      logout() {
         this.replaceState(getDefaultState());
      },
      login(state, payload) {
         state.userdata = payload;
      },
      users(state, payload) {
         state.users = payload;
      },
      products(state, payload) {
         state.products = payload;
      },
      licencias(state, payload) {
         state.licencias = payload;
      },
      public_key(state, payload) {
         state.public_key = payload;
      },
      addhardwares(state,payload){
         state.hardwares = payload;
         console.log('datos: ->',payload);
      },
   },
   actions: {
      async login(context, payload) {
         try {
            let res = await axios.post("login", {
               username: payload.username,
               password: payload.password,
            });
            context.commit("login", res.data);
            axios.defaults.headers.common["API-KEY"] = context.state.userdata.token;
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      //GET
      async getUsuarios(context) {
         try {
            let res = await axios.get("users");
            context.commit("users", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      async getPublicKey(context) {
         try {
            let res = await axios.get("licencias/public");
            context.commit("public_key", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      async getProductos(context) {
         try {
            let res = await axios.get("products");
            context.commit("products", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      async getDownloadLicense(context, payload) {
         try {
            let res = await axios.get("licencias/download", {
               params: payload,
            });
            return res;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      async getLicencias(context, payload) {
         try {
            if (!payload) {
               payload = context.state.licencias.options;
            }
            let res = await axios.get("licencias", {
               params: payload,
            });
            context.commit("licencias", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      //POST
      async insertUsuario(context, payload) {
         try {
            await axios.post("users", payload);
            context.dispatch("getUsuarios");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      async insertProducto(context, payload) {
         try {
            await axios.post("products", payload);
            context.dispatch("getProductos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      async insertLicencia(context, payload) {
         try {
            await axios.post("licencias", payload);
            context.dispatch("getLicencias");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      //PUT
      async updateUsuario(context, payload) {
         try {
            await axios.put("users", payload);
            context.dispatch("getUsuarios");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      async updateKeys(context, payload) {
         try {
            return await axios.put("api_keys", payload);
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      async updateProducto(context, payload) {
         try {
            await axios.put("products", payload);
            context.dispatch("getProductos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      async updateLicencia(context, payload) {
         try {
            await axios.put("licencias", payload);
            context.dispatch("getLicencias");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      //DELETE
      async deleteUsuario(context, payload) {
         try {
            await axios.delete("users", {
               data: payload,
            });
            context.dispatch("getUsuarios");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      async deleteProducto(context, payload) {
         try {
            await axios.delete("products", {
               data: payload,
            });
            context.dispatch("getProductos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      //Licencias de Hardwares
      async deleteHardwares(context,payload) {
         try {
            const res = await axios.delete("licenciascy/delete", {
               data: {
                     licencia: payload.item.hardware_id,
                     hardware_id: payload.item.licencia_id
               }
            });
            console.log(res);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      },
      async getHardware(state,context) {
         try {
            const res = await axios.get("licenciascy/g", {
               params: {
                  licencia: this.state.selectedHardware['licencia_id']
               }
            });
            this.state.hardwares = res.data;
            return;
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            if (error.response) {
               return error.response;
            } else {
               return error;
            }
         }
      }
   },
});
