<template>
     <div>
          <v-app-bar app color="primary">
               <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
               <div class="mx-2 text-h6 d-none d-sm-flex">
                    {{ this_route }}
               </div>
               <v-spacer></v-spacer>
               <v-app-bar-title>
                    <v-btn color="hovermenu" elevation="8" @click="$router.push({ name: 'Dashboard' }, () => {})">
                         {{ app_name }}
                    </v-btn>
               </v-app-bar-title>
               <v-spacer></v-spacer>
               <div class="mx-2 text-h6 d-none d-sm-flex" style="visibility: hidden">
                    {{ this_route }}
               </div>
               <div v-if="is_logged">
                    <v-btn
                         icon
                         @click="
                              $store.commit('logout');
                              $router.push({ name: 'Login' }, () => {});
                         "
                    >
                         <v-icon>mdi-exit-to-app</v-icon>
                    </v-btn>
               </div>
               <div v-else>
                    <v-btn icon @click="$router.push({ name: 'Login' }, () => {})">
                         <v-icon>mdi-account-circle</v-icon>
                    </v-btn>
               </div>
          </v-app-bar>
          <v-navigation-drawer color="primary" v-model="drawer" fixed temporary>
               <template v-slot:prepend>
                    <v-list-item two-line>
                         <v-list-item-avatar>
                              <v-icon large>mdi-account-circle</v-icon>
                         </v-list-item-avatar>
                         <v-list-item-content>
                              <v-list-item-title>{{ user_name }}</v-list-item-title>
                         </v-list-item-content>
                    </v-list-item>
               </template>
               <template v-slot:append>
                    <v-list-item two-line>
                         <v-list-item-avatar>
                              <v-icon large>mdi-theme-light-dark</v-icon>
                         </v-list-item-avatar>
                         <v-list-item-content>
                              <v-list-item-title class="px-4">
                                   <v-switch  v-model="dark" inset color="secondary"></v-switch>
                              </v-list-item-title>
                         </v-list-item-content>
                    </v-list-item>
               </template>
               <v-divider></v-divider>
               <v-list nav dense>
                    <v-list-item-group v-model="group">
                         <div v-for="(item, index) in $router.options.routes" :key="index">
                              <v-list-item v-if="show_route(item)" @click="$router.push({ name: item.name }, () => {})">
                                   <v-list-item-icon
                                        ><v-icon dense>{{ item.meta.icon }}</v-icon></v-list-item-icon
                                   >
                                   <v-list-item-title>{{ item.meta.title }}</v-list-item-title>
                              </v-list-item>
                         </div>
                    </v-list-item-group>
               </v-list>
          </v-navigation-drawer>
     </div>
</template>

<script>
import c from "../constant";

export default {
     computed: {
          user_name: function () {
               if (this.$store.state.userdata.token.length > 0) {
                    return this.$store.state.userdata.username;
               }
          },
          app_name: function () {
               return c.APP_NAME;
          },
          this_route: function () {
               if (this.$route.meta.show) {
                    return this.$route.meta.title;
               }
          },
          is_logged: function () {
               if (this.$store.state.userdata.token) {
                    return this.$store.state.userdata.token.length > 0;
               }
               return false;
          },
     },
     data: () => ({
          drawer: false,
          group: null,
          dark: false,
     }),
     methods: {
          show_route(item) {
               return item.meta.show;
          },
     },
     watch: {
          group() {
               this.drawer = false;
          },
          dark() {
               this.$vuetify.theme.dark = this.dark;
               localStorage.setItem("dark", JSON.stringify(this.dark));
          },
     },
     created() {
          this.dark = this.$vuetify.theme.dark;
     },
};
</script>
